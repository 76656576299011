<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          class="mr-2"
          v-on="on">
          mdi-folder-multiple-image
        </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="setDialog(false)">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="request-image__container">
            <div v-if="images && images.length">
              <v-img
                v-for="(image, i) in images"
                :key="`request-image-${i}`"
                :src="image"
                class="mb-4" />
            </div>
            <div
              v-else
              class="d-flex justify-center align-center h-100">
              {{ $t('app.page.request.emptyImage') }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'RequestImage',
  props: {
    images: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    setDialog (value) {
      this.dialog = value
    }
  }
}
</script>

<style scoped>
  .request-image__container {
    height: 400px;
    overflow-y: auto;
  }
</style>
