var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"request"},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('app.page.request.title'))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"value":_vm.search,"placeholder":_vm.$t('app.placeholder.search'),"prepend-inner-icon":"mdi-magnify","dense":"","outlined":"","hide-details":""},on:{"input":function($event){return _vm.debounceSearching($event)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"placeholder":_vm.$t('app.placeholder.filter'),"items":_vm.filters,"item-text":"text","item-value":"value","clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"loading-text":_vm.$t('vuetify.loading'),"no-data-text":_vm.$t('vuetify.noData'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"request__chip",attrs:{"color":_vm.colorType(item.location.type),"label":"","small":""}},[_vm._v(" "+_vm._s(item.location.type || _vm.displayTypeLocation)+" ")])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"caption",staticStyle:{"width":"70px"}},[_vm._v(" "+_vm._s(_vm.displayDiffTime(item.createdAt))+" ")])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('request-image',{attrs:{"images":item.images}}),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.map(item)}}},[_vm._v(" mdi-map-marker-radius-outline ")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":("tel:" + (item.phoneNo))}},[_c('v-icon',[_vm._v(" mdi-phone-in-talk ")])],1)],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"200px"}},[(item.status === 'PENDING')?_c('v-btn',{staticClass:"request__button",attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){return _vm.changeStatus(item.id, 'ACCEPTED')}}},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('app.btn.accept'))+" ")])]):_vm._e(),(item.status === 'ACCEPTED')?_c('v-btn',{staticClass:"request__button mr-2",attrs:{"color":"success","depressed":"","small":""},on:{"click":function($event){return _vm.changeStatus(item.id, 'SOLVED')}}},[_c('div',{staticClass:"caption"},[_vm._v(" เสร็จสิ้น ")])]):_vm._e(),(item.status === 'ACCEPTED')?_c('v-btn',{staticClass:"request__button",attrs:{"color":"error","depressed":"","small":""},on:{"click":function($event){return _vm.changeStatus(item.id, 'REJECTED')}}},[_c('div',{staticClass:"caption"},[_vm._v(" ยกเลิกคำขอ ")])]):_vm._e()],1)]}}])}),_c('div',{staticClass:"d-flex justify-center justify-md-end mt-6"},[_c('v-pagination',{attrs:{"length":_vm.totalPage,"total-visible":5},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }