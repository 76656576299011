<template>
  <div class="request">
    <v-row class="mb-4">
      <v-col
        cols="12"
        md="8">
        <h1> {{ $t('app.page.request.title') }} </h1>
      </v-col>
      <v-col
        cols="12"
        md="2">
        <v-text-field
          :value="search"
          :placeholder="$t('app.placeholder.search')"
          prepend-inner-icon="mdi-magnify"
          dense
          outlined
          hide-details
          @input="debounceSearching($event)" />
      </v-col>
      <v-col
        cols="12"
        md="2">
        <v-select
          v-model="selectedFilter"
          :placeholder="$t('app.placeholder.filter')"
          :items="filters"
          item-text="text"
          item-value="value"
          clearable
          dense
          outlined
          hide-details />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isLoading"
      :loading-text="$t('vuetify.loading')"
      :no-data-text="$t('vuetify.noData')"
      hide-default-footer>
      <template v-slot:item.type="{ item }">
        <v-chip
          :color="colorType(item.location.type)"
          class="request__chip"
          label
          small>
          {{ item.location.type || displayTypeLocation }}
        </v-chip>
      </template>
      <template v-slot:item.time="{ item }">
        <div
          style="width: 70px;"
          class="caption">
          {{ displayDiffTime(item.createdAt) }}
        </div>
      </template>
      <template v-slot:item.options="{ item }">
        <div class="d-flex">
          <request-image :images="item.images" />
          <v-icon
            class="mr-2"
            @click="map(item)">
            mdi-map-marker-radius-outline
          </v-icon>
          <a
            :href="`tel:${item.phoneNo}`"
            class="text-decoration-none">
            <v-icon>
              mdi-phone-in-talk
            </v-icon>
          </a>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="caption">
          {{ item.status }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div
          style="width: 200px;"
          class="d-flex justify-center">
          <v-btn
            v-if="item.status === 'PENDING'"
            color="primary"
            class="request__button"
            depressed
            small
            @click="changeStatus(item.id, 'ACCEPTED')">
            <div class="caption">
              {{ $t('app.btn.accept') }}
            </div>
          </v-btn>
          <v-btn
            v-if="item.status === 'ACCEPTED'"
            color="success"
            class="request__button mr-2"
            depressed
            small
            @click="changeStatus(item.id, 'SOLVED')">
            <div class="caption">
              เสร็จสิ้น
            </div>
          </v-btn>
          <v-btn
            v-if="item.status === 'ACCEPTED'"
            color="error"
            class="request__button"
            depressed
            small
            @click="changeStatus(item.id, 'REJECTED')">
            <div class="caption">
              ยกเลิกคำขอ
            </div>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <div class="d-flex justify-center justify-md-end mt-6">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EmergencyCallProvider from '@/resources/axios/providers/emergency-call'
import RequestImage from '@/views/request/components/RequestImage.vue'

export default {
  name: 'Request',
  components: {
    RequestImage
  },
  data () {
    return {
      page: 1,
      limit: 7,
      totalPage: 1,
      search: '',
      isLoading: true,
      headers: [
        { text: this.$t('app.table.header.locationName'), sortable: false, value: 'location.name' },
        { text: this.$t('app.table.header.description'), sortable: false, value: 'note' },
        { text: this.$t('app.table.header.telephone'), sortable: false, value: 'phoneNo' },
        { text: this.$t('app.table.header.type'), sortable: false, value: 'type' },
        { text: this.$t('app.table.header.province'), sortable: false, value: 'location.province' },
        { text: this.$t('app.table.header.district'), sortable: false, value: 'location.district' },
        { text: this.$t('app.table.header.subDistrict'), sortable: false, value: 'location.subDistrict' },
        { text: this.$t('app.table.header.time'), sortable: false, value: 'time' },
        { text: '', sortable: false, value: 'options' },
        { text: this.$t('app.table.header.status'), sortable: false, value: 'status' },
        { text: '', sortable: false, value: 'actions' }
      ],
      items: [],
      selectedFilter: null,
      filters: [
        {
          text: 'Status',
          value: 'status'
        }
      ],
      connection: null
    }
  },
  computed: {
    ...mapGetters({
      myLocation: 'local/myLocation'
    })
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'myLocation.lat' () {
      this.getRequest()
    },
    selectedFilter () {
      this.getRequest()
    },
    page () {
      this.getRequest()
    }
  },
  created () {
    this.debounceSearching = this.$_.debounce(this.setSearch, 800)
    this.initWebsocket()
  },
  mounted () {
    if (this.myLocation.lat && this.myLocation.lng) {
      this.getRequest()
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    initWebsocket () {
      const uri = 'wss://api-rescue-station.duskyhub.com/emergency-calls/ws'
      const ws = new WebSocket(uri)

      ws.onopen = () => {
        console.log('Connected')
      }

      ws.onmessage = (evt) => {
        console.log('evt', evt)
        this.getRequest()
      }
      // const loc = window.location
      // let uri = 'ws://'

      // if (loc.protocol === 'https:') {
      //   uri = 'wss://'
      // }
      // uri += `${loc.host }/emergency-calls/ws`

      // console.log(uri)
      // this.connection = new WebSocket(uri)

      // this.connection = new WebSocket('ws://location:8080/emergency-calls/ws')
      // this.connection = new WebSocket('wss://management-rescue-station.duskyhub.com/emergency-calls/ws')

      // this.connection = new WebSocket('wss://api-rescue-station.duskyhub.com/emergency-calls/ws')
      // this.connection.onopen = () => {
      //   console.log('connected websocket')
      // }
      // this.connection.onmessage = () => {
      //   console.log('alert data')
      //   this.getRequest()
      // }
      // const socket = io('https://api-rescue-station.duskyhub.com')
      // socket.on('message', (data) => {
      //   console.log('data', data)
      // })
    },
    displayDiffTime (time) {
      const date1 = this.$dayjs(time)
      const date2 = this.$dayjs()
      const diff = date2.diff(date1, 'm')
      let cal = null

      if (diff >= 1440) {
        cal = this.$_.parseInt(diff / 1440)
        return `${cal} วันที่แล้ว`
      }
      if (diff >= 60) {
        cal = this.$_.parseInt(diff / 60)
        return `${cal} ชม.ที่แล้ว`
      }
      if (diff < 1) {
        return 'เมื่อสักครู่นี้'
      }
      return `${diff} นาทีที่แล้ว`
    },
    setSearch (search) {
      this.search = search
      this.getRequest()
    },
    colorType (type) {
      if (type === 'rescue') {
        return 'primary'
      } if (type === 'fire') {
        return 'warning'
      }
      return 'error'
    },
    async getRequest () {
      try {
        this.isLoading = true
        const res = await EmergencyCallProvider.getEmergencyCallList({
          page: this.page,
          limit: this.limit,
          eagerLoad: 'Location',
          filter: this.selectedFilter ? `&${this.selectedFilter}=${this.search}` : ''
        })
        if (res) {
          this.items = res.records
          this.totalPage = res.totalPage
          this.isLoading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    async changeStatus (id, value) {
      try {
        const res = await EmergencyCallProvider.patchEmergencyCall(id,
          {
            status: value
          })
        if (res) {
          this.showErrorMessage({
            message: this.$t('app.alert.status'),
            type: 'success'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
          this.getRequest()
        }
      } catch (error) {
        console.error(error)
      }
    },
    map (item) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${this.myLocation.lat},${this.myLocation.lng}&destination=${item.emergencyLatitude},${item.emergencyLongitude}`,
        '_blank'
      )
    }
  }
}
</script>

<style scoped>
  .request {
    padding: 16px 24px;
  }
  .request__chip.v-chip {
    padding: 0px 8px;
  }
  .request__button.v-btn {
    padding: 0px 8px;
  }
</style>
