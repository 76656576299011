import HttpRequest from '@/resources/axios/core/http-request'
import store from '@/store'

class EmergencyCallProvider extends HttpRequest {
  getEmergencyCallList = (params) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get(
      `/emergency-calls?page=${params.page}&limit=${params.limit}&orderBy=id&sortBy=desc&eagerLoad=${params.eagerLoad}${params.filter}`
    )
  }

  getEmergencyCallOne = (param) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get(`/emergency-calls/${param}`)
  }

  patchEmergencyCall = (param, payload) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.patch(`/emergency-calls/${param}`, payload)
  }
}

export default new EmergencyCallProvider()
